import { Navigate, Route, useRoutes } from 'react-router-dom';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register.js';
import HomeLayout from '../layouts/main';
//import Home from '../pages/home/Home';
import Destination from '../pages/destination/Destination';
import About from '../pages/about/About';
import Faq from '../pages/faq/Faq';
import Contact from '../pages/contact/Contact';
import TourDetails from '../pages/tour/TourDetails';
import Activity from '../pages/activity/Activity';
import ActivityDetails from '../pages/activity/ActivityDetails';
import Booking from '../pages/booking/Booking';
import AuthGuard from '../guards/AuthGuard';
import RegionDetails from '../pages/region/RegionDetails';
import Page404 from '../pages/404/Page404';
// import BookingList
import DestinationDetails from '../pages/destination/DestinationDetails';
import CustomizeTrip from '../pages/CustomizeTrip/CustomizeTrip';
import ViewBooking from '../pages/viewBooking/ViewBooking';
import Blog from '../pages/blog/Blog';
import BlogDetails from '../pages/blogDetails/BlogDetails';
import ContentPage from '../pages/contentPage/ContentPage';
import TeamList from '../pages/TeamList/TeamList';
import TeamDetails from '../pages/teamDetails/TeamDetails';

import Test from '../pages/test/Test';
import Dashboard from '../pages/dashboard/Dashboard';
import EditProfile from '../pages/dashboard/EditProfile';
import ChangePassword from '../pages/dashboard/ChangePassword';
import Invoices from '../pages/dashboard/Invoices';
import Reviews from '../pages/dashboard/Reviews';
import MyBookings from '../pages/dashboard/MyBookings';
import WishList from '../pages/dashboard/WishList';
import Loading from '../components/loading/Loading';
import {   Suspense, lazy  } from 'react';

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return (
      <Suspense fallback={<Loading/>}>
        <Component {...props} />
      </Suspense>
    );
  };
  
  const Homelayoutmain = Loadable(lazy(() => (import('../layouts/main'))));
const HomePagePreview = Loadable(lazy(() => (import('../pages/home/Home'))));
 
export default function Router() {
    return useRoutes([
        {
            path: 'auth',
            children: [
                { path: 'login', element: <Login /> },
                { path: 'register', element: <Register /> },
                { path: 'register/:slug', element: <Register /> },
            ],
        },
        {
            
            path: '/dashboard',
            element: <Homelayoutmain />,
            children: [
                { element: localStorage.getItem('token') ? <Dashboard /> : <Navigate to="/auth/login"/>, index: true },
                { path: 'edit-profile', element: localStorage.getItem('token') ? <EditProfile/> : <Navigate to="/auth/login"/> },
                { path: 'change-password', element: localStorage.getItem('token') ? <ChangePassword/> : <Navigate to="/auth/login"/> },
                { path: 'invoices', element: localStorage.getItem('token') ? <Invoices/> : <Navigate to="/auth/login"/> },
                { path: 'reviews', element: localStorage.getItem('token') ? <Reviews/> : <Navigate to="/auth/login"/> },
                { path: 'my-bookings', element: localStorage.getItem('token') ? <MyBookings/> : <Navigate to="/auth/login"/> },
                { path: 'wishlist', element: localStorage.getItem('token') ? <WishList/> : <Navigate to="/auth/login"/> },
                 
            ],
        },
        {
            path: '/',
            element: <Homelayoutmain />,
            children: [
                { element: <HomePagePreview />, index: true },
                { path: 'home', element: <HomePagePreview /> },
                { path: 'about', element: <About /> },
                { path: 'faq', element: <Faq /> },
                { path: 'contact', element: <Contact /> },
                { path: ':dest/:slug', element: <TourDetails /> },
                { path: 'customize-trip', element: <CustomizeTrip /> },
                { path: 'view-booking', element: <ViewBooking /> },
                { path: 'blog', element: <Blog /> },
                { path: 'blog-details/:slug', element: <BlogDetails /> },
                { path: 'support/:slug', element: <ContentPage /> },
                { path: 'team-list', element: <TeamList /> },
                { path: 'team/:slug', element: <TeamDetails /> },
                { path: 'region/:slug', element: <RegionDetails /> },
                { path: 'test', element: <Test /> },
            ],
        },
        {
            path: '/destination',
            element: <Homelayoutmain />,
            children: [
                { element: <Destination />, index: true },
                { path: ':slug', element: <DestinationDetails /> },
            ],
        },
      
        {
            path: '/',
            element: (
                // <AuthGuard>
                <Homelayoutmain />
                // </AuthGuard>
            ),
            children: [{ path: 'payment', element: <Booking /> }],
        },
        {
            path: '/activity',
            element: <Homelayoutmain />,
            children: [
                { element: <Activity />, index: true },
                { path: ':slug', element: <ActivityDetails /> },
            ],
        },
        {
            path: '/style',
            element: <Homelayoutmain />,
            children: [{ element: <Activity />, index: true }],
        },
        {
            path: '*',
            // element: <LogoOnlyLayout />,
            children: [
              { path: '404', element: <Page404 /> },
              { path: '*', element: <Navigate to="/404" replace /> },
            ],
          },
    ]);
}
