import * as React from 'react';
import Nav from './Nav';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { useEffect, useState } from 'react';
import { reviewApi } from '../../service/Review.service';

import { Box,Table,TableBody ,Typography,Breadcrumbs, TableCell,TableContainer, TableHead,TableRow,Paper} from '@mui/material';

const Reviews = () => {
  const [rows,setRows] = useState([]);
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
      }
      function createData(tourName, reviewDate, rating, review, status) {
        return { tourName, reviewDate, rating, review, status };
      }
      
      // const rows = [
      //   createData('Napal', '23/09/2023', 6.0, 'Paid'),
      //   createData('kashmir', '05/07/2023', 6.0, 'Paid'),
      //   createData('Sikim', '23/07/2023', 6.0, 'Paid'),
      //   createData('Kerala', '23/09/2023', 6.0, 'Paid'),
      // ];  

      useEffect( ()=>{
        async function fetchData(){
          const userData = JSON.parse(localStorage.getItem('user'));
          const user_id = userData['id'];
          const customerReviewlist = await reviewApi.customerReviewlist(user_id);
          let rowReviews = [];
          if(typeof customerReviewlist!=='undefined' &&  customerReviewlist.data.data.length > 0){
            customerReviewlist.data.data.map((dataRow,Index)=>{
               const tourName = typeof dataRow.get_tour.title!=='undefined' && dataRow.get_tour.title;
               const reviewDate = dataRow.ratingDate;
               const rating = dataRow.rating;
               const review = dataRow.review;
               let status = '';
               if(dataRow.status === 0){
                 status = 'Pendng';
               }
               if(dataRow.status === 1){
                   status = 'Accepted';
                }
                if(dataRow.status === 2){
                  status = 'Rejected';
                }
                rowReviews.push(createData(tourName,reviewDate,rating,review,status));


          
            })

          }
          setRows(rowReviews);

        }
         fetchData();
      },[]);


  return (
    <>
        <Box className='accountBg' style={{ background: 'url(../images/blog.jpg) top center no-repeat' }}></Box>
        <Box className='dashBoardSection d-flex relative'>
            <Box className='navigationArea'>
                <Nav/>
            </Box>
                
            <Box className='dbRight'>
                <div className='breadeCambs' role="presentation" onClick={handleClick}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                    Dashboard
                    </Link>
                    
                    <Typography color="text.primary">Reviews</Typography>
                  </Breadcrumbs>
                </div>
                <Box className='myProfile cmnWhtSadow'>
    
                    <Box className='boxBody'>
                      <Box className='boxBodyTable'>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Tour Name</TableCell>
                                <TableCell align="right">Review Date</TableCell>
                                <TableCell align="right">Rating</TableCell>
                                <TableCell align="right">Review</TableCell>
                                <TableCell align="right">Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows && rows.map((row) => (
                                <TableRow
                                  key={row.tourName}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.tourName}
                                  </TableCell>
                                  <TableCell align="right">{row.reviewDate}</TableCell>
                                  <TableCell align="right">{row.rating}</TableCell>
                                  <TableCell align="right">{row.review}</TableCell>
                                  <TableCell align="right">{row.status}</TableCell>
                                </TableRow>
                              ))}
                              
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </>
  )
}

export default Reviews