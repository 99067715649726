import * as React from 'react';
import Nav from './Nav';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';

import { Box,Table,TableBody ,Typography,Breadcrumbs, TableCell,TableContainer, TableHead,TableRow,Paper} from '@mui/material';

const WishList = () => {
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
      }
      function createData(tourName, action) {
        return { tourName, action };
      }
      
      const rows = [
        createData('Napal', 'Action'),
        createData('kashmir', 'Action'),
        createData('Sikim', 'Action'),
        createData('Kerala', 'Action'),
      ];
  return (
    <>
        <Box className='accountBg' style={{ background: 'url(../images/blog.jpg) top center no-repeat' }}></Box>
        <Box className='dashBoardSection d-flex relative'>
            <Box className='navigationArea'>
                <Nav/>
            </Box>
                
            <Box className='dbRight'>
                <div className='breadeCambs' role="presentation" onClick={handleClick}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                    Dashboard
                    </Link>
                    
                    <Typography color="text.primary">Invoices</Typography>
                  </Breadcrumbs>
                </div>
                <Box className='myProfile cmnWhtSadow'>
                  <Box className='boxBodyNav'>
                        <ul className='d-flex flex-wrap'>
                          <li>
                            <Link top={'/'} className='active'>All</Link>
                          </li>
                          <li>
                            <Link top={'/'}>Pending</Link>
                          </li>
                          <li>
                            <Link top={'/'}>Approved</Link>
                          </li>
                          <li>
                            <Link top={'/'}>Receipt Submitted</Link>
                          </li>
                          <li>
                            <Link top={'/'}>Fully Paid</Link>
                          </li>
                          <li>
                            <Link top={'/'}>Deposit Paid</Link>
                          </li>
                          <li>
                            <Link top={'/'}>Departed</Link>
                          </li>
                          <li>
                            <Link top={'/'}>Rejected</Link>
                          </li>
                          <li>
                            <Link top={'/'}>Wait For Approval</Link>
                          </li>
                        </ul>
                      </Box>
                    <Box className='boxBody'>
                      <Box className='boxBodyTable'>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Tour Name</TableCell>
                                <TableCell align="right">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row) => (
                                <TableRow
                                  key={row.tourName}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.tourName}
                                  </TableCell>
                                  <TableCell align="right">{row.action}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </>
  )
}

export default WishList