import React from 'react';
import { styled } from '@mui/material/styles';

import { Box, Paper, Typography, Stack, Avatar } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import { AiFillEdit, AiOutlineDashboard, AiOutlineHeart } from 'react-icons/ai';
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineClipboardList } from 'react-icons/hi';
import { TbFileInvoice } from 'react-icons/tb';
import { MdOutlineReviews } from 'react-icons/md';
import { VscSignOut } from 'react-icons/vsc';
import { ImPhone } from 'react-icons/im';
import { FaEnvelope } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';


const Nav = () => {

  const navigate = useNavigate();
  const handleLogout = async () => {
        localStorage.clear();
        navigate(`/home`);
   };
  return (
    <>
    <Box className='navigationBox'>
        <Box className='navigationBoxInner'>
            <h5>My Account</h5>
            <ul>
                <li>
                    <Link className='wat' to={'/dashboard'}><AiOutlineDashboard/> Dashboard</Link>
                </li>
                <li>
                    <Link to={'/dashboard/edit-profile'}><AiFillEdit/> Edit Profile</Link>
                </li>
                <li>
                    <Link to={'/dashboard/change-password'}><RiLockPasswordLine />Change Password</Link>
                </li>
            </ul>
        </Box>
        <Box className='navigationBoxInner'>
            <h5>Tour Booking</h5>
            <ul>
                <li>
                    <Link to={'/dashboard/my-bookings'}><HiOutlineClipboardList/>My Bookings</Link>
                </li>
                {/* <li>
                    <Link to={'/dashboard/invoices'}><TbFileInvoice/> Invoices</Link>
                </li> */}
                <li>
                    <Link to={'/dashboard/reviews'}><MdOutlineReviews/>Reviews</Link>
                </li>
            </ul>
        </Box>
        <Box className='signOutBox'>
            <Link onClick={handleLogout}><VscSignOut/> Sign Out</Link>
        </Box>
        <Box className='NeedHelp'>
            <h3>Need Help?</h3>
            <ul>
                <li><ImPhone/> +977 98510 94157</li>
                <li><Link to={'/'}><FaEnvelope/> Info@HimalayanLeisure.Com</Link></li>
            </ul>
        </Box>
    </Box>
    </>
  )
}

export default Nav