import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Parallax } from 'react-parallax';
import OurDestination from '../../section/destination/OurDestination';
import Filter from '../../section/destination/Filter';
import { destinationApi, DestinationContentService } from '../../service/Destination.service';
import { Link } from 'react-router-dom';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#fb5d36'),
    backgroundColor: '#f97150',
    borderRadius: 3,
    padding: '0 22px',
    lineHeight:'44px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    fontSize:'14px',
    fontFamily: 'Montserrat',
    boxShadow:'none',
    '@media (max-width:768px)': {
      lineHeight:'34px',
      fontSize:'11px',
      padding: '0 15px' ,
    },
    '&:hover': {
      backgroundColor: '#d04726',
    },
  }));

const Destination = () => {
   
    const [destinationList, setDestinationList] = useState([]);
    const [difficultyList, setDifficultyList] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);
  const [loadingTwo, setLoadingTwo] = useState(true);
  const [loadingThree, setLoadingThree] = useState(true);
  const [loadingFour, setLoadingFour] = useState(true);

    useEffect(() => {
        getDestination();
        getDestinationContent();
        getActivity();
        
    }, []);

    const getDestinationContent = async () => {
        const result = await DestinationContentService();
        if (result.status === 200) {
            setContent(result.data.data);
            setLoading(false);
        }
    };

    const getDestination = async () => {
        const destination = await destinationApi.list();
        if (destination.status === 200 && destination.data.status === 200 && destination.data.success === true) {
            await setDestinationList(destination.data.data);
            setLoadingTwo(false);
            getDifficulty();
        }
    };

    const getDifficulty = async () => {
        const difficulty = await destinationApi.difficultyList();
        if (difficulty.status === 200 && difficulty.data.status === 200 && difficulty.data.success === true) {
            await setDifficultyList(difficulty.data.data);
            setLoadingThree(false);
            // setLoading(false);
        }
    };

    const getActivity = async () => {
        const activity = await destinationApi.activityList();
        if (activity.status === 200 && activity.data.status === 200 && activity.data.success === true) {
            await setActivityList(activity.data.data);
            setLoadingFour(false);
            // setLoading(false);
        }
    };

    return (
        <>
            {loading || loadingTwo || loadingThree || loadingFour ? (
               <div className="preload">
               <div className="logo-container">
               <img src="../images/loader.svg" alt="" className="logo" />
                
               </div>
           </div>
            ) : (
                <>
                    <Box className='aboutBan relative'>
                        <Parallax
                            bgImage={`${process.env.REACT_APP_HOST_IMAGE}image/destination/${content?.content?.banner_img}`}
                            bgImageAlt=''
                            strength={100}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '600px',
                                    maxWidth: '1170px',
                                    width: '100%',
                                    margin: '0 auto',
                                }}
                                className='aboutBanSlogan relative ph-80'
                            >
                                <Grid
                                    container spacing={{ xs: 2, md: 3 , lg: 3 }} padding={{xs: 2, md: 2 , lg: 3}}alignItems={'center'}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <Grid item xs={12} md>
                                        <Typography
                                            variant='h2'
                                            sx={{
                                                fontSize: '50px',
                                                fontWeight: 'bold',
                                                color: '#f97150',
                                            }}
                                        >
                                            {content?.content?.title}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                color: '#fff',
                                                fontSize: '15px',
                                            }}
                                        >
                                            {content?.content?.sub_title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={'auto'}>
                                        <Link to={`/contact`} className='themeButton'>
                                            Contact Us
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Parallax>
                    </Box>
                    <OurDestination myMargin='5' destination={destinationList} content={content} />
                    <Filter destination={destinationList} difficulty={difficultyList} activity={activityList} />
                </>
            )}
        </>
    );
};

export default Destination;
