import React from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import { Box, Button, FormControlLabel, Checkbox  } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';

import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
  } from '@stripe/react-stripe-js';
  import { useState } from 'react';

const SubmitButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#fb5d36'),
    backgroundColor: '#f97150',
    borderRadius: 4,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: "bold",
    boxShadow: 'none',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: '#d04726',
    },
  }));



const CheckoutForm = (props) => {

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading,setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!props.iAgree){
        enqueueSnackbar('Please select I Agree !', {variant:'error'} );
        return;
    }
    setLoading(true);

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
     
      enqueueSnackbar(submitError.message, {variant:'error'} );
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    
    const res = await fetch(`${process.env.REACT_APP_HOST_API}checkout/create-intent?amount=${props.amount}`);

    // {
    //   method: 'POST',
    // }

    const {id: transactionid, client_secret: clientSecret} = await res.json();


   

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
          return_url: 'https://example.com/order/123/complete',
      },
      redirect: 'if_required'
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      
      enqueueSnackbar(error.message, {variant:'error'} );
      setLoading(false);
    } else {
     //setLoading(false);
      props.handleBooking({
        id:transactionid,
        status:'COMPLETED'
      })
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Box className='' sx={{ marginTop: '1rem' }} >
            <FormControlLabel control={<Checkbox name='iAgree' value={props.iAgree} checked={props.iAgree} onChange={()=>{props.setIAgreeStripe(!props.iAgree)}} />} label="* I agree with Terms of Service and Privacy Statement." />
      </Box>
      <Box>
        <SubmitButton onClick={handleSubmit} variant="contained" className="LearnMoreBtn" sx={{ marginTop: "30px", width: '100%', }}  disabled={!stripe || !elements || loading} >
                                         {loading ? 'Processing...' : 'Pay'}  <ArrowForwardIcon />
           </SubmitButton>
    </Box>

      


      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const stripePromise = loadStripe('pk_test_1T5AxayoGokarcq3QRDmp2n3');

const StripePayment = (props) => {
    
    const options = {
        mode: 'payment',
        amount: parseFloat(props.amount) * 100,
        currency: 'usd',
        // Fully customizable with appearance API.
        appearance: {
          /*...*/
        },
      };
  return (<Elements stripe={stripePromise}  options={options}>
    <CheckoutForm amount={props.amount} iAgree={props.iAgree} setIAgreeStripe={props.setIAgreeStripe} handleBooking={props.handleBooking} />
  </Elements>);
};

export default StripePayment;
