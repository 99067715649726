import * as React from 'react';
import Nav from './Nav';
import { styled } from '@mui/material/styles';

import { Box,Grid,TextField ,InputLabel ,MenuItem ,FormControl,Select ,Typography,Breadcrumbs, Button, Alert } from '@mui/material';
import { purple } from '@mui/material/colors';

import Link from '@mui/material/Link';
import { helperApi } from '../../service/Helper.service';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { authApi } from '../../service/Auth.service';
 


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#fb5d36'),
  backgroundColor: '#f97150',
  borderRadius: 6,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 10,
  paddingBottom: 10,
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  '&:hover': {
      backgroundColor: '#d04726',
  },
}));

const MONTH_LIST = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];

const EditProfile = () => {
  const [countryData, setCountryData] = React.useState([]);
  
  const [userId,setUserId] = React.useState(0);
  const [firstName,setFirstName] = React.useState('');

  const [lastName,setlastName] = React.useState('');
  const [email,setemail] = React.useState('');
  const [phoneNumber,setPhoneNumber] = React.useState('');

  const { enqueueSnackbar } = useSnackbar();


  const [age, setAge] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [year, setYear] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [loading,setLoading] = React.useState(false);
  const [error,setError] = React.useState([]);
  const uploadElement = React.useRef(); 
  const [uploadImageFile,setUploadImageFile] = React.useState('');
  const [uploadedImageFile,setUploadedImageFile] = React.useState('');



  useEffect(()=>{
    fetchCountry();
      const userData = JSON.parse(localStorage.getItem('user'));
      setemail(userData['email']);
      setPhoneNumber(userData['contact']);
      setCountry(userData['country']);
      setGender(userData['gender']);
      setUserId(userData['id'])

      const nameSplit = userData['name'].split(' ');
      setFirstName(typeof nameSplit[0]!=='undefined' ? nameSplit[0] : '');
      setlastName(typeof nameSplit[1]!=='undefined' ? nameSplit[1] : '');
      const dob = userData['dob'].split('-');
      setYear(typeof dob[0]!=='undefined' ? +dob[0] : '');
      setMonth(typeof dob[1]!=='undefined' ? parseInt(dob[1]) : '');
      setAge(typeof dob[2]!=='undefined' ? parseInt(dob[2]) : '');

      console.log(userData);

      if(userData['profile_pic']!==''){
        setUploadedImageFile(`${process.env.REACT_APP_HOST_IMAGE}image/profile-pic/${userData['profile_pic']}`);
      }
  },[]);




  const fetchCountry = async() => {
    const countrydata = await helperApi.country();
    setCountryData(countrydata.data.data);
  }
  const handleChangeOne = (event) => {
    setAge(event.target.value);
  };
  const handleChangeTwo = (event) => {
    setMonth(event.target.value);
  };
  const handleChangeThree = (event) => {
    setYear(event.target.value);
  };
  function handleFirstName(event){
    setFirstName(event.target.value);
  }
  function handleLastName(event){
    setlastName(event.target.value);
  }
  function handleemail(event){
    setemail(event.target.value);
  }
  function handlePhoneNumber(event){
    setPhoneNumber(event.target.value);
  }
  const handleChangeFour = (event) => {
       setGender(event.target.value);
  };
  const handleChangeFive = (event) => {
      setCountry(event.target.value);
  };
  async function submithandle(event){
    setLoading(true);
    event.preventDefault();
    const postData =  {userId,firstName,lastName,email,phoneNumber,age,month,year, gender,country,uploadImageFile};
    const editProfileProcess = await authApi.editProfile(postData);
    
    if (editProfileProcess.status === 400 && editProfileProcess.data.success === false) {
      setError(prev=>[...prev,editProfileProcess.data.message]);
      setLoading(false);
    } else if (editProfileProcess.status === 200 && editProfileProcess.data.success === true) {
      enqueueSnackbar('Profile details successfully updated !');
      localStorage.setItem('user',JSON.stringify(editProfileProcess.data.user));
      setLoading(false);
    }
  }
  function triggerUpload(){
    uploadElement.current.click();
  }
  function uploadImageProcess(e){
      const  file = e.target.files;
      const  fileReader = new FileReader();
      fileReader.onload = (function (e) {
        
        // html = '<input type="hidden" name="bannerimage" value="' + e.target.result + '">' +
        //      '<input type="hidden" name="bannerimageName" value="' + imagefiles[0].name + '">';
        //   $('.banner-selected-images').html(html);  
        
        setUploadImageFile(e.target.result);
     });
     fileReader.readAsDataURL(file[0]);


  }

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  return (
    <>
    <Box className='accountBg' style={{ background: 'url(../images/blog.jpg) top center no-repeat' }}></Box>


    <Box className='dashBoardSection d-flex relative'>
            <Box className='navigationArea'>
                <Nav/>
            </Box>
                
            <Box className='dbRight'>
                <div className='breadeCambs' role="presentation" onClick={handleClick}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                    Dashboard
                    </Link>
                    
                    <Typography color="text.primary">Edit Profile</Typography>
                  </Breadcrumbs>
                </div>
                <Box className='myProfile cmnWhtSadow'>
                    <Box className='boxBody d-flex flex-wrap'>
                      <Box className='uploadImg textCenter'>
                        <span>
                          {uploadImageFile && <img src={uploadImageFile} alt="" />   }
                          {(uploadedImageFile && !uploadImageFile) && <img src={uploadedImageFile} alt="" />  }

                          {(!uploadedImageFile && !uploadImageFile) && <img src='/images/Default_pfp.svg.png' alt="" />}
                          
                           </span>
                        <ColorButton onClick={triggerUpload} variant="contained">Change Profile Picture</ColorButton>
                        <input onChange={uploadImageProcess}  accept="image/png, image/gif, image/jpeg" type="file" ref={uploadElement} style={{'display':'none'}} />
                      </Box>
                      <Box className='myProfileRight'>
                        {/* <h3 className='textCenter'>Profile</h3> */}
                        <Box sx={{ flexGrow: 1 }}>
                          <form action='get'>
                          <Grid container spacing={2}>
                          {error && error.map((error,index)=> <Grid key={index} item xs={12} md={12} lg={12}><Alert severity="error">{error}</Alert></Grid> )}
                              
                            <Grid item xs={12} md={6} lg={6}>
                              <Box>
                                <TextField value={firstName} fullWidth onInput={handleFirstName} label="First Name" id="fullWidth" />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <Box>
                                <TextField value={lastName} fullWidth label="Last Name" onInput={handleLastName} id="fullWidth" />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                            <Box>
                                <TextField value={email} fullWidth label="Email" onInput={handleemail} id="fullWidth" />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8}>
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid container spacing={2}>
                                <Grid item xs={4} md={4} lg={4}>
                                  <Box>
                                    <FormControl fullWidth>
                                      <InputLabel id="age">Day</InputLabel>
                                      <Select
                                        labelId="age"
                                        id="demo-1"
                                        value={age}
                                        label="Day"
                                        onChange={handleChangeOne}
                                      >
                                         {(() => {
        const rows = [];
        for (let i = 1; i <= 31; i++) {
          rows.push(<MenuItem value={i}>{i}</MenuItem>);
        }
        return rows;
      })()}
                                        
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                <Box>
                                    <FormControl fullWidth>
                                      <InputLabel id="month">Month</InputLabel>
                                      <Select
                                        labelId="month"
                                        id="demo-2"
                                        value={month}
                                        label="Month"
                                        onChange={handleChangeTwo}
                                      >

                                        {MONTH_LIST.map((monthName,index)=><MenuItem value={index+1}>{monthName}</MenuItem>)}
                                        
                                        
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                <Box>
                                    <FormControl fullWidth>
                                      <InputLabel id="year">Year</InputLabel>
                                      <Select
                                        labelId="year"
                                        id="demo-3"
                                        value={year}
                                        label="Year"
                                        onChange={handleChangeThree}
                                      >
                                        {(() => {
        const rows = [];
        for (let i = new Date().getFullYear() ; i >= 1970; i--) {
          rows.push(<MenuItem value={i}>{i}</MenuItem>);
        }
        return rows;
      })()}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                              <Box>
                                  <FormControl fullWidth>
                                    <InputLabel id="gender">Gender</InputLabel>
                                    <Select
                                      labelId="gender"
                                      id="demo-4"
                                      value={gender}
                                      label="Gender"
                                      onChange={handleChangeFour}
                                    >
                                      <MenuItem value={'Male'}>Male</MenuItem>
                                      <MenuItem value={'Female'}>Female</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <Box>
                                <TextField value={phoneNumber} fullWidth label="Phone No" onInput={handlePhoneNumber} id="fullWidth" />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                              <Box>
                                  <FormControl fullWidth>
                                    <InputLabel id="country">Country</InputLabel>
                                    <Select
                                      labelId="country"
                                      id="demo-5"
                                      value={country}
                                      label="Country"
                                      onChange={handleChangeFive}
                                    >

{countryData && countryData.map((countryd, index) => (
                        <MenuItem key={countryd.id} value={countryd.id}>{countryd.name}</MenuItem>
                      ))}
                                       
                                    </Select>
                                  </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Box>
                                    <ColorButton disabled={loading} onClick={submithandle} variant="contained" className='w-100'>
                                      {loading ? 'Processing...' : 'Update Profile'}
                                      </ColorButton>
                                </Box>
                            </Grid>
                          </Grid>
                          </form>
                        </Box>
                      </Box>
                    </Box>
                </Box>
            </Box>
        </Box>









    </>
  )
}

export default EditProfile