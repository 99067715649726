import React from 'react';
import Nav from './Nav';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import { BsPencilSquare } from 'react-icons/bs';
import { useEffect } from 'react';

const Dashboard = () => {


    const [name,setName] = React.useState('');
    const [email,setemail] = React.useState('');
    const [phoneNumber,setPhoneNumber] = React.useState('');
    const [age, setAge] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [year, setYear] = React.useState('');
    const [gender, setGender] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [uploadImageFile,setUploadImageFile] = React.useState('');

    useEffect(()=>{
          const userData = JSON.parse(localStorage.getItem('user'));
          setemail(userData['email']);
          setPhoneNumber(userData['contact']);
          setCountry(userData['country']);
          setGender(userData['gender']);
          setName(userData['name']);
          setCountry(userData['countryName']);
          const dob = userData['dob']!==null  ? userData['dob'].split('-') :  '';
          setYear(typeof dob[0]!=='undefined' ? +dob[0] : '');
          setMonth(typeof dob[1]!=='undefined' ? parseInt(dob[1]) : '');
          setAge(typeof dob[2]!=='undefined' ? parseInt(dob[2]) : '');

          if(userData['profile_pic']!==''){
            setUploadImageFile(`${process.env.REACT_APP_HOST_IMAGE}image/profile-pic/${userData['profile_pic']}`);
          }
      },[]);

    
  return (
    <>
        <Box className='accountBg' style={{ background: 'url(../images/blog.jpg) top center no-repeat' }}></Box>
        <Box className='dashBoardSection d-flex relative'>
            <Box className='navigationArea'>
                <Nav/>
            </Box>
                
            <Box className='dbRight'>
                <Box className='myProfile cmnWhtSadow'>
                    <Box className='boxHeader d-flex flex-wrap align-items-center justify-content-between'>
                        <div className="boxHeaderLeft">
                            <h5>My Profile</h5>
                        </div>
                        <div className="boxHeaderRight">
                            <Link to={'/dashboard/edit-profile'}> <BsPencilSquare/> Edit Profile</Link>
                        </div>
                    </Box>
                    <Box className='boxBody d-flex flex-wrap'>
                        <div className="proFileImg">
                            {/* <span><img src={`${process.env.REACT_APP_URL}images/service-excellence/1.jpg`} alt="" /></span> */}
                            <span>
                          {uploadImageFile ? <img src={uploadImageFile} alt="" /> : <img src='/images/Default_pfp.svg.png' alt="" /> }
                          
                           </span>

                        </div>
                        <div className="profileDesk">
                            <ul className='d-flex flex-wrap'>
                                <li><span className='info'>Name</span> <span className='value'>{name}</span></li>
                                <li><span className='info'>Gender</span> <span className='value'>{gender}</span></li>
                                <li><span className='info'>Birth Date</span> <span className='value'>
                                    {(age && month && year) &&  `${month}/${age}/${year}` }
                                    </span></li>
                                <li><span className='info'>Country</span> <span className='value'>{country}</span></li>
                                <li><span className='info'>Email</span> <span className='value'>{email}</span></li>
                                <li><span className='info'>Phone</span> <span className='value'>{phoneNumber}</span></li>
                            </ul>
                        </div>
                    </Box>
                </Box>
            </Box>
        </Box>
    </>
  )
}

export default Dashboard