import * as React from 'react';
import Nav from './Nav';
import { styled } from '@mui/material/styles';
import { Box,Grid,TextField ,Typography,Breadcrumbs, Button , Alert} from '@mui/material';
import Link from '@mui/material/Link';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { authApi } from '../../service/Auth.service';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#fb5d36'),
    backgroundColor: '#f97150',
    borderRadius: 6,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    '&:hover': {
        backgroundColor: '#d04726',
    },
  }));


const ChangePassword = () => {
    const [oldPassword,setOldPassword] = useState('');
    const [newPassword,setNewPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const [error,setError] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [loadingRequest, setLoadingRequest ] = useState(false);

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
      }
      function handleOldpassword(event){
          setOldPassword(event.target.value);
      }
      function handleNewPassword(event){
          setNewPassword(event.target.value);
      }
      function handleConfirmPassword(event){
        setConfirmPassword(event.target.value);
      }
      const  handleChangePassword = async (event)=>{
        event.preventDefault();
        setError([]);
        let hasError = false;
        if(oldPassword === ''){
          hasError = true;
          setError(prev=>[...prev,'Old password required']);
        }
        if(newPassword === ''){
          hasError = true;
          setError(prev=>[...prev,'New password  required']);
        }
         if(newPassword.length < 6){
          hasError = true;
          setError(prev=>[...prev,'New password  minimum length 6']);
        }
        if(confirmPassword === ''){
          hasError = true;
          setError(prev=>[...prev,'Confirm password  required']);
        }
        if(confirmPassword !== newPassword){
          hasError = true;
          setError(prev=>[...prev,'New password and confirm password should be same']);
        }
        
        if(hasError === false){
          setLoadingRequest(true);
              const user_id = JSON.parse(localStorage.getItem('user'))['id'];
              const postData = {user_id:user_id,oldPassword, newPassword};
              // console.log(postData);
                const changePasswordProcess = await authApi.changePassword(postData);
                if (changePasswordProcess.status === 400 && changePasswordProcess.data.success === false) {
                  setError(prev=>[...prev,changePasswordProcess.data.message]);
                  setLoadingRequest(false);
                } else if (changePasswordProcess.status === 200 && changePasswordProcess.data.success === true) {
                  enqueueSnackbar('Password succesdfully changes !');
                  setOldPassword('');
                  setNewPassword('');
                  setConfirmPassword('');
                  setLoadingRequest(false);
                }
        }

       
      };

  return (
    <>
        <Box className='accountBg' style={{ background: 'url(../images/blog.jpg) top center no-repeat' }}></Box>
        <Box className='dashBoardSection d-flex relative'>
            <Box className='navigationArea'>
                <Nav/>
            </Box>
                
            <Box className='dbRight'>
                <div className='breadeCambs' role="presentation" onClick={handleClick}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                    Dashboard
                    </Link>
                    
                    <Typography color="text.primary">Change Password</Typography>
                  </Breadcrumbs>
                </div>
                
                <Box className='myProfile cmnWhtSadow'>
                    <Box className='boxBody d-flex flex-wrap'>
                   
                      <Box className='myProfileInner'>
                        <h3 className='textCenter'>Change Password</h3>
                       
                        <Box sx={{ flexGrow: 1 }}>
                        
                          <form action='get'>
                          <Grid container spacing={2}>
                          {error && error.map((error,index)=> <Grid key={index} item xs={12} md={12} lg={12}><Alert severity="error">{error}</Alert></Grid> )}
                            <Grid item xs={12} md={12} lg={12}>
                              <Box>
                                <TextField value={oldPassword} onInput={handleOldpassword} autoComplete='off' fullWidth label="Old Password*" id="fullWidth" type="password" />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                              <Box>
                                <TextField value={newPassword} autoComplete='off' onInput={handleNewPassword} fullWidth label="New Password*" id="fullWidth" type="password"/>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                            <Box>
                                <TextField value={confirmPassword} autoComplete='off' onInput={handleConfirmPassword} fullWidth label="Confirm Password*" id="fullWidth" type="password"/>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Box>
                                    <ColorButton disabled={loadingRequest} variant="contained" onClick={handleChangePassword} className='w-100'>
                                    {loadingRequest ?  'Processing...' : 'Update Password'}
                                      
                                      </ColorButton>
                                </Box>
                            </Grid>
                          </Grid>
                          </form>
                        </Box>
                      </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </>
  )
}

export default ChangePassword