import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Paper, Typography, Stack, Avatar } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Parallax } from 'react-parallax';
import Button from '@mui/material/Button';

import ContactTwo from '../../section/contact/ContactTwo';
import GetTouch from '../../section/contact/GetTouch';
import ServiceExcellence from '../../section/contact/ServiceExcellence';
import WhatClientSay from '../../section/contact/WhatClientSay';
import WeAreMember from '../../section/contact/WeAreMember';

import { contactContentService } from '../../service/Contact.service.js';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#fb5d36'),
    backgroundColor: '#f97150',
    borderRadius: 3,
    padding: '0 22px',
    lineHeight:'44px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    fontSize:'14px',
    fontFamily: 'Montserrat',
    boxShadow:'none',
    '@media (max-width:768px)': {
      lineHeight:'34px',
      fontSize:'11px',
      padding: '0 15px' ,
    },
    '&:hover': {
      backgroundColor: '#d04726',
    },
  }));

const Contact = () => {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getContactContent();
    }, []);

    const getContactContent = async () => {
        const result = await contactContentService();
        if (result.status === 200) {
            setContent(result.data.data);
            setLoading(false);
        }
    };
    if(loading){
        return ( <div className="preload">
        <div className="logo-container">
        <img src="../images/loader.svg" alt="" className="logo" />
         
        </div>
    </div>);
    }

    return (
        <>
        <Helmet>
                        <title>{content?.content?.metaTitle}</title>
                        <meta name="description" content={content?.content?.metaDescription} />
                        <meta name="keywords" content={content?.content?.metaKeyword} />
                </Helmet>
            <Box className='aboutBan relative'>
                <Parallax
                    bgImage={`${process.env.REACT_APP_HOST_IMAGE}image/contact/${content?.content?.banner_img}`}
                    bgImageAlt=''
                    strength={100}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            alignItems: 'center',
                            height: '600px',
                            maxWidth: '1170px',
                            width: '100%',
                            margin: '0 auto',
                        }}
                        className='aboutBanSlogan relative ph-80'
                    >
                        <Grid container spacing={{ xs: 2, md: 3 , lg: 3 }} padding={{xs: 2, md: 2 , lg: 3}}alignItems={'center'}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Grid item xs={12} md>
                                <Typography
                                    variant='h2'
                                    sx={{
                                        fontSize: '50px',
                                        fontWeight: 'bold',
                                        color: '#f97150',
                                    }}
                                >
                                    {content?.content?.title}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    sx={{
                                        color: '#fff',
                                        fontSize: '15px',
                                    }}
                                >
                                    {content?.content?.sub_title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={'auto'}>
                                <Link to={`/contact`} className='themeButton'>
                                    Contact Us
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Parallax>
            </Box>
            <ContactTwo content={content} />
            <GetTouch />
            <ServiceExcellence />
            <WhatClientSay />
            <WeAreMember />
        </>
    );
};

export default Contact;
