import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Parallax } from 'react-parallax';
import AboutSecTwo from '../../section/region/AboutSecTwo';
import Filter from '../../section/region/Filter';
import { destinationApi } from '../../service/Destination.service';
// import { activityApi } from '../../service/Activity.service';
import parse from 'html-react-parser';
import { GiWalk } from "react-icons/gi";
import { regionApi } from '../../service/Region.service';
import removeTags from '../../utils/removeTags';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#fb5d36'),
  backgroundColor: '#f97150',
  borderRadius: 3,
  padding: '0 22px',
  lineHeight:'44px',
  fontWeight: '600',
  whiteSpace: 'nowrap',
  fontSize:'14px',
  fontFamily: 'Montserrat',
  boxShadow:'none',
  '@media (max-width:768px)': {
    lineHeight:'34px',
    fontSize:'11px',
    padding: '0 15px' ,
  },
  '&:hover': {
    backgroundColor: '#d04726',
  },
}));

const RegionDetails = () => {
  const params = useParams();
  
  const [region, setRegion] = useState('');
  const [regionContent, setRegionContent] = useState('');
  const [destinationList, setDestinationList] = useState([]);
  const [difficultyList, setDifficultyList] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingTwo, setLoadingTwo] = useState(true);
  const [loadingThree, setLoadingThree] = useState(true);

  useEffect(() => {
  
    getRegion();
    getRegionContect();
  },[params]);

  const getRegion = async () => {
    const region = await regionApi.regionBySlug(params.slug);
    if (region.status === 200 && region.data.status === 200 && region.data.success === true) {
      await setRegion(region.data.data);
      const destination = await destinationApi.list();
      if (destination.status === 200 && destination.data.status === 200 && destination.data.success === true) {
        await setDestinationList(destination.data.data);
        setLoading(false);
        getDifficulty();
      }
    }
  }

  const getDifficulty = async () => {
    const difficulty = await destinationApi.difficultyList();
    if (difficulty.status === 200 && difficulty.data.status === 200 && difficulty.data.success === true) {
      await setDifficultyList(difficulty.data.data);
      setLoadingTwo(false);
    }
  }

  const getRegionContect = async () => {
    const res = await regionApi.regionContentService();
    if (res.status === 200 && res.data.status === 200) {
      await setRegionContent(res.data.data);
      setLoadingThree(false);
    }
  }

  return (
    <>
    {loading || loadingTwo || loadingThree ?  
        ( 
          <div className="preload">
        <div className="logo-container">
        <img src="../images/loader.svg" alt="" className="logo" />
         
        </div>
    </div>
        
         )
        
        
        
         : (
      <>
        <Box className='aboutBan relative'>
          <Parallax bgImage={`${process.env.REACT_APP_HOST_IMAGE}image/region/banner/${region.regionImage}`} bgImageAlt="" strength={100}>
            <Box sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              height: '600px',
              maxWidth: '1170px',
              width: '100%',
              margin: '0 auto',
              position: 'relative'
            }}
              className='aboutBanSlogan relative ph-80'
            >
              <Grid container spacing={{ xs: 2, md: 3 , lg: 3 }} padding={{xs: 2, md: 2 , lg: 3}} alignItems={"center"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width:'100%',
                }}
              >
                <Grid item xs={12} md>
                  <Typography variant="h2"
                    sx={{
                      fontSize: "50px",
                      fontWeight: 'bold',
                      color: '#f97150',
                    }}
                  >
                    {region.name}
                  </Typography>
                  <Typography variant="body2"
                    sx={{
                      color: "#fff",
                      fontSize: '15px',
                    }}
                  >
                    {removeTags(region.description).slice(0, 150)}
                    
                  </Typography>
                </Grid>
                <Grid item xs={12} md={'auto'}>
                  <ColorButton variant="contained" className="LearnMoreBtn">Contact Us</ColorButton>
                </Grid>
              </Grid>
            </Box>
          </Parallax>
          <span className='banIcon'><GiWalk /></span>
        </Box>
        <AboutSecTwo region={region} />
        <Filter region={region} destination={destinationList} difficulty={difficultyList} />
      </>
    )}
    </>
  )
}

export default RegionDetails;