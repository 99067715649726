import { makeStyles } from "@mui/styles";
import { color, height } from "@mui/system";

export const useStyles = makeStyles({
    root: {
        background: "#ffe3dc",
        borderRadius: 6,
        padding: "10px 20px",
        "@media (max-width:1366px)": {
            padding: "10px",
        },
    },
    label: {
        textTransform: "capitalize",
        color: "red",
    },
    pl30: {
        paddingLeft: "30px !important",
        position: "relative",
    },
    noPadding: {
        padding: "0 !important",
    },
    heightwidth: {
        height: "42px",
        width: "42px",
        "@media (max-width:1366px)": {
            height: "30px",
            width: "30px",
        },
    },

    customMinWidth: {
        minWidth: "unset",
        marginRight: "12px",
    },

    inputbox: {
        border: "none ",
        height: "48px",
        "& > fieldset": { borderColor: "#fff !important", height: "36px," },
    },
    cssLabel: {
        color: "#fff",
        // "&.Mui-focused": {
        //   color: "#fff"
        // }
    },
    iconContainer: { // define styles for icon container
      transform: 'scale(2)',
    }
});
