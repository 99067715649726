import * as React from 'react';
import Nav from './Nav';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { useEffect, useState } from 'react';
import { bookingApi } from '../../service/Booking.service';
import { Box,Table,TableBody ,Typography,Breadcrumbs, TableCell,TableContainer, TableHead,TableRow,Paper} from '@mui/material';

const MyBookings = () => {
  const [rows,setRows] = useState([]);
  
  
  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  function createData(tourName, travelDate, total, paymentStatus,paymentType,transactionid) {
    return { tourName, travelDate, total, paymentStatus, paymentType,transactionid };
  }



  useEffect( ()=>{
    async function fetchData(){
      const userData = JSON.parse(localStorage.getItem('user'));
      const user_id = userData['id'];
      const email = userData['email'];
      const myBooking = await bookingApi.getMyBooking(user_id,email);
      let rowReviews = [];
      if(typeof myBooking!=='undefined' &&  myBooking.data.data.length > 0){
        myBooking.data.data.map((dataRow,Index)=>{
           const tourName = typeof dataRow.get_tour.title!=='undefined' && dataRow.get_tour.title;
           const travelDate = dataRow.tourStartDate;
           const total = dataRow.grossAmount;
           const paymentStatus = dataRow.transactionStatus;
           const paymentType = dataRow.paymentType;
           const transactionid = dataRow.transactionId;
           rowReviews.push(createData(tourName,travelDate,total,paymentStatus, paymentType,transactionid));
        })
      }
      setRows(rowReviews);
    }
     fetchData();
  },[]);


  
  // const rows = [
  //   createData('Napal', '23/09/2023', 6.0, 'Paid'),
  //   createData('kashmir', '05/07/2023', 6.0, 'Paid'),
  //   createData('Sikim', '23/07/2023', 6.0, 'Paid'),
  //   createData('Kerala', '23/09/2023', 6.0, 'Paid'),
  // ];


  return (
    <>
        <Box className='accountBg' style={{ background: 'url(../images/blog.jpg) top center no-repeat' }}></Box>
        <Box className='dashBoardSection d-flex relative'>
            <Box className='navigationArea'>
                <Nav/>
            </Box>
                
            <Box className='dbRight'>
                <div className='breadeCambs' role="presentation" onClick={handleClick}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                    Dashboard
                    </Link>
                    
                    <Typography color="text.primary">My Bookings</Typography>
                  </Breadcrumbs>
                </div>
                <Box className='myProfile cmnWhtSadow'>
                    <Box className='boxBody'>
                      <Box className='boxBodyTable'>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Tour Name</TableCell>
                                <TableCell align="right">Travel Date</TableCell>
                                <TableCell align="right">Total</TableCell>
                                <TableCell align="right">Payment Status</TableCell>
                                <TableCell align="right">Payment Type</TableCell>
                                <TableCell align="right">transaction id</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows && rows.map((row) => (
                                <TableRow
                                  key={row.tourName}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.tourName}
                                  </TableCell>
                                  <TableCell align="right">{row.travelDate}</TableCell>
                                  <TableCell align="right">${row.total}</TableCell>
                                  <TableCell align="right">{row.paymentStatus}</TableCell>
                                  <TableCell align="right">{row.paymentType}</TableCell>
                                  <TableCell align="right">{row.transactionid}</TableCell>
                                  

                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </>
  )
}

export default MyBookings