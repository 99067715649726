import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Parallax } from 'react-parallax';
import AboutSecTwo from '../../section/destination/AboutSecTwo';
import OurDestination from '../../section/destination/OurDestination';
import DestinationTab from '../../section/destination/DestinationTab';
import Filter from '../../section/destination/destinationDetails/Filter';
import { destinationApi } from '../../service/Destination.service';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#fb5d36'),
    backgroundColor: '#f97150',
    borderRadius: 3,
    padding: '0 22px',
    lineHeight:'44px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    fontSize:'14px',
    fontFamily: 'Montserrat',
    boxShadow:'none',
    '@media (max-width:768px)': {
      lineHeight:'34px',
      fontSize:'11px',
      padding: '0 15px' ,
    },
    '&:hover': {
      backgroundColor: '#d04726',
    },
  }));

const DestinationDetails = () => {
     
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingTwo, setLoadingTwo] = useState(true);
    const [loadingThree, setLoadingThree] = useState(true);
    const [destination, setDestination] = useState('');
    const [destinationList, setDestinationList] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const [difficultyList, setDifficultyList] = useState([]);

    useEffect(() => {
        getDestination();
        getActivity();
      
    }, [params]);

    const getDestination = async () => {
        const destinationBySlug = await destinationApi.destinationBySlug(params.slug);

        if (
            destinationBySlug.status === 200 &&
            destinationBySlug.data.status === 200 &&
            destinationBySlug.data.success === true
        ) {
            await setDestination(destinationBySlug.data.data);
            setLoading(false);
            // const destination = await destinationApi.list();
            // if (destination.status === 200 && destination.data.status === 200 && destination.data.success === true) {
            //     await setDestinationList(destination.data.data);
                getDifficulty();
            // }
        }
    };

    const getDifficulty = async () => {
        const difficulty = await destinationApi.difficultyList();
        if (difficulty.status === 200 && difficulty.data.status === 200 && difficulty.data.success === true) {
            await setDifficultyList(difficulty.data.data);
            // await setLoading(false);
            setLoadingTwo(false);
        }
    };

    const getActivity = async () => {
        const activity = await destinationApi.activityList();
        if (activity.status === 200 && activity.data.status === 200 && activity.data.success === true) {
            await setActivityList(activity.data.data);
            // setLoading(false);
            setLoadingThree(false);
        }
    };

    return (
        <>
            {loading || loadingTwo || loadingThree ? (
               <div className="preload">
               <div className="logo-container">
               <img src="../images/loader.svg" alt="" className="logo" />
                
               </div>
           </div>
            ) : (
                <>
                    <Box className='aboutBan relative'>
                        <Parallax
                            bgImage={`${process.env.REACT_APP_HOST_IMAGE}image/destination/${destination?.content?.banner_img}`}
                            bgImageAlt=''
                            strength={100}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '600px',
                                    maxWidth: '1170px',
                                    width: '100%',
                                    margin: '0 auto',
                                }}
                                className='aboutBanSlogan relative ph-80'
                            >
                                <Grid
                                    container spacing={{ xs: 2, md: 3 , lg: 3 }} padding={{xs: 2, md: 2 , lg: 3}} alignItems={'center'}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width:'100%'
                                    }}
                                >
                                    <Grid item xs={12} md>
                                        <Typography
                                            variant='h2'
                                            sx={{
                                                fontSize: '50px',
                                                fontWeight: 'bold',
                                                color: '#f97150',
                                            }}
                                        >
                                            {destination?.content?.banner_title}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                color: '#fff',
                                                fontSize: '15px',
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: destination?.content?.banner_description,
                                            }}
                                        ></Typography>
                                    </Grid>
                                    <Grid item xs={12} md={'auto'}>
                                        <Link to={`/contact`} className='themeButton'>
                                            Contact Us
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Parallax>
                    </Box>
                    <AboutSecTwo destination={destination} />
                    <DestinationTab destination={destination} />
                    <Filter destinationDetails={destination} difficulty={difficultyList} activity={activityList} />
                </>
            )}
        </>
    );
};

export default DestinationDetails;
